@import '../../assets/style/mixins';

.container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  text-align: left;
  justify-content: space-between;
  @include mobile-portrait{
    flex-direction: column;
  }

  @include mobile-landscape {
    height: 100vh;
  }

  background: #1A1B35;
  font-size: 28px;
  line-height: 36px;
}

.textContent {
  @include for-mobile {
    padding: 0 var(--mobile-padding) 4rem var(--mobile-padding);
  }
  @include mobile-portrait{
    position: unset;
    width: 100vw;
  }
  @include mobile-landscape {
    width: 60vw;
  }
  position: absolute;
  height: 100%;
  padding: 10rem 12rem 5.5rem 5.5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;

  color: #FFFFFF;

  @include titleFontSize;

  h2 {
    margin-bottom: 2.5rem;
  }

  @include for-mobile {
    h1, h2 {
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
    }
    h2 {
      margin-bottom: 1rem;
    }
  }

  .listItem {
    display: flex;
    align-items: center;
    height: 3rem;
    margin-top: 0.5rem;

    font-size: 18px;
    line-height: 24px;
    @media only screen and (max-height: 600px) and (min-width: 813px) {
      font-size: 3vh;
      line-height: 3.5vh;
      min-height: 4vh;
      svg {
        height: 4vh;
        width: 4vh;
      }
    }

    svg {
      margin-right: 1rem;
    }
    @include for-mobile{
      height: auto;
      font-size: 14px;
      line-height: 21px;
      svg {
        display: none;
      }
    }
  }
}

.circledBackground {

  @include mobile-portrait{
    height: 140vw;
    width: 140vw;
    min-width: 140vw;
    margin-top: -15vh;
    margin-left: -20vw;
  }

  @include mobile-landscape {
    height: 140vh;
    width: 140vh;
    min-width: 140vh;
    margin-right: -15vh;
    margin-top: -20vh;
  }

  height: 120vh;
  width: 120vh;
  margin-left: auto;
  margin-top: -10vh;
  margin-right: calc(5.5rem - 15vh);
  min-width: 120vh;

  background: #1A1B35;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .circle {
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    position: absolute;
  }

  .circle0 {
    @include for-mobile{
      display: none;
    }
    width: 128%;
    height: 128%;

    .image {
      width: 4rem;
      height: 4rem;
      @include not-mobile {
        @media (max-height: 500px) {
          height: 12.8vh;
          width: 12.8vh;
        }
      }
      margin-top: 75%;
      margin-left: 6.69%;
      transform: translate(-50%, -50%);
      background-image: image-set(
                      url("../../assets/images/05x/scroll5_4.png") 0.5x,
                      url("../../assets/images/scroll5_4.png") 1x
      );
      background-repeat: no-repeat;
      background-position: center;

      background-size: contain;
    }
  }

  .circle1 {
    width: 74%;
    height: 74%;

    .image {
      @include for-mobile{
        width: 3rem;
        height: 3rem;
      }
      @include mobile-landscape {
        display: none;
      }
      width: 5rem;
      height: 5rem;
      @include not-mobile {
        @media (max-height: 500px) {
          height: 16vh;
          width: 16vh;
        }
      }
      margin-top: 85.35%;
      margin-left: 85.35%;
      transform: translate(-50%, -50%);
      background-image: image-set(
                      url("../../assets/images/05x/scroll5_3.png") 0.5x,
                      url("../../assets/images/scroll5_3.png") 1x
      );
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  .circle2 {
    width: 48.5%;
    height: 48.5%;

    .image {
      @include for-mobile{
        width: 2rem;
        height: 2rem;
      }
      @include not-mobile {
        @media (max-height: 500px) {
          height: 12.8vh;
          width: 12.8vh;
        }
      }
      width: 4rem;
      height: 4rem;
      margin-top: 6.7%;
      margin-left: 75%;
      transform: translate(-50%, -50%);
      background-image: image-set(
                      url("../../assets/images/05x/scroll5_2.png") 0.5x,
                      url("../../assets/images/scroll5_2.png") 1x
      );
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  .circle3 {
    width: 31.5%;
    height: 31.5%;
    display: flex;
    align-items: flex-end;

    .image {
      @include for-mobile{
        width: 1.5rem;
        height: 1.5rem;
      }
      @include not-mobile {
        @media (max-height: 500px) {
          height: 9.6vh;
          width: 9.6vh;
        }
      }
      width: 3rem;
      height: 3rem;
      margin-bottom: 6.7%;
      margin-left: 25%;
      transform: translate(-50%, 50%);
      background-image: image-set(
                      url("../../assets/images/05x/scroll5_1.png") 0.5x,
                      url("../../assets/images/scroll5_1.png") 1x
      );
      background-repeat: no-repeat;
      background-position: center;

      background-size: contain;
    }
  }

  .circle4 {
    width: 18%;
    height: 18%;

    .image {
      width: 100%;
      height: 100%;
      background-image: image-set(
                      url("../../assets/images/05x/scroll5_0.png") 0.5x,
                      url("../../assets/images/scroll5_0.png") 1x
      );
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
}
