@import '../../assets/style/mixins';

.container {
  @include scrollPageStyles;
  position: absolute;
  background-color: var(--background-color-grey);
  color: var(--darkblue);
  display: flex;
  justify-content: space-between;

  @include for-mobile {
    position: relative;
    padding: 4rem 0 0 0;
    display: flex;
    flex-direction: column;
  }

  & > div {
    width: calc(50% - 20px);
    @include for-mobile {
      width: 100%;
    }
  }

  .rightBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(77vh - 11.2rem);
    @include onReducePadding {
      width: calc(77vh - 7.2rem);
      min-width: 12rem;
      min-height: 15rem;
    }
    @include for-mobile {
      width: 100%;
    }
  }
}

.text {
  margin-left: 2rem;
  grid-area: text;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  @include for-mobile {
    padding: 0 var(--mobile-padding);
    margin: 0 0 1.5rem;
  }

  .benefits {
    grid-area: benefits;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 1rem;
    margin-top: 3vh;

    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    @media only screen and (max-height: 800px), only screen and (max-width: 1400px) {
      font-size: 15px;
      line-height: 22px;
      grid-row-gap: 0.5rem;
    }
    @media only screen and (max-height: 600px) and (min-width: 813px){
      font-size: 12px;
      line-height: 18px;
      grid-row-gap: 0.5rem;
    }
    @media only screen and (min-width: 813px) and (max-width: 1000px) and (min-height: 600px){
      font-size: 12px;
      line-height: 18px;
      grid-row-gap: 0.5rem;
    }
    @include for-mobile {
      grid-template-rows: 2rem 2rem 2rem;
      font-weight: 400;
      font-size: 14px;
      margin-top: 1rem;
      grid-row-gap: 0;
    }
  }

  .title {
    .hidden {
      display: none;
    }

    @media only screen and (max-height: 550px) and (min-width: 813px){
      width: 100%;
      display: flex;
      br, h2 {
        display: none;
      }
      .hidden {
        display: inline;
      }
    }
    @include for-mobile {
      width: 100%;
      display: flex;
      br {
        display: none;
      }
      .hidden {
        display: inline;
      }
    }
  }

  @include titleFontSize;

  h1 {
    @include for-mobile {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
    }
  }
  h2 {
    @include for-mobile {
      display: none;
    }
  }
  h1, h2 {
    @media only screen and (min-width: 813px) and (max-width: 1000px) and (min-height: 600px){
      font-size: 20px;
      line-height: 23px;
    }
  }
}

.presentationBlock {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  height: 100%;
  width: 100%;
  overflow: hidden;
  @include mobile-landscape {
    flex-direction: row-reverse;
  }

  .carouselContainer {
    height: 70%;
    min-height: 70%;
    @include for-mobile {
      width: 100vw;
      height: 100vw;
    }
    @include mobile-landscape {
      width: 50vw;
      height: 50vw;
    }
  }

  .textCarouselContainer {
    flex: 1;
    margin-top: 1.5vh;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    @media only screen and (max-height: 750px), only screen and (max-width: 900px) {
      font-size: 14px;
      line-height: 18px;
    }
    @media only screen and (max-height: 500px) and (min-width: 813px){
      font-size: 10px;
      line-height: 13px;
      margin-top: 0.5vh;
    }
    @include for-mobile {
      height: 5rem;
      margin-bottom: 5rem;
    }
    @include mobile-landscape {
      font-weight: 400;
    }
  }

  .bottomPart {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 3vh;
    @media only screen and (max-height: 650px) and (min-width: 813px){
      padding: 2vh;
    }
    @include for-mobile {
      padding-left: var(--mobile-padding);
      padding-right: var(--mobile-padding);
    }
    @include mobile-landscape {
      padding-top: var(--mobile-padding);
      .navigationBar {
        padding-bottom: 1rem;
      }
    }
  }
}

.imageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .image0 {
    min-width: 100%;
    min-height: 100%;
    @include for-mobile {
      min-width: unset;
    }
  }

  img {
    height: 80%;
  }
}

.overlayQRCodeContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

}

.tryNow {
  &.desktop {
    @include for-mobile {
      display: none;
    }
  }

  &.mobile {
    display: none;
    bottom: 2rem;
    left: 3vh;
    height: calc(4rem + 2px);
    @include for-mobile {
      display: flex;
    }
    @include mobile-landscape {
      bottom: 1.5rem;
      left: 1rem;
    }

    svg {
      height: 4rem;
      width: 4rem;
      padding-left: 1px;
    }
  }

  position: absolute;
  bottom: 0;
  left: 0;

  outline: none;
  border: none;
  padding: unset;
  background: none;
  display: flex;
  align-items: center;

  cursor: pointer;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  font-variant: small-caps;
  font-weight: 600;
  color: var(--active-color);

  svg {
    height: 6rem;
    width: 6rem;
    @media only screen and (max-height: 650px) and (min-width: 813px){
      height: 4rem;
      width: 4rem;
    }
    margin-right: 1rem;
    transform: none;
  }
}
