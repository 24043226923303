@import "../assets/style/variables";
@import '../assets/style/mixins';

.container {
  width: 100%;
  white-space: pre-wrap;
  padding: 10rem 5.5rem 5.5rem 5.5rem;
  background-color: var(--background-color-grey);
  text-align: left;
  color: var(--darkblue);
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0.05rem;


  a {
    color: var(--darkblue);

    &:visited, &:active {
      color: var(--darkblue);
    }

    text-decoration: underline;
  }

  p {
    margin: 1rem 0;
  }

  .title {
    font-weight: 200;
    font-size: 3.75rem;
    margin-bottom: 2rem;

    b {
      font-weight: 800;
    }
  }

  .introduce {
    margin-bottom: 1rem;
    width: calc(100% - 8rem);
  }

  h3 {
    font-weight: 800;
  }

  .block {
    display: grid;
    grid-template-columns: calc(50% - 6rem) calc(50% - 6rem);
    grid-column-gap: 4rem;
    margin-bottom: 1.5rem;

    .subtitle {
      margin-bottom: 0.5rem;
    }

    .doubleBlock {
      display: contents;
    }

    .wide {
      margin-top: -1rem;
      grid-column-start: 1;
      grid-column-end: 3;
    }

    .subtitle {
      margin-top: 1rem;
      padding: 0;
      font-weight: 800;
      font-size: 1.125rem;

      &.grey {
        color: var(--grey-font);
        margin-top: 0.5rem;
      }
    }
  }

  @include for-mobile{
    padding: 6rem 2rem 2rem 2rem;
    font-size: 14px;
    p {
      text-align: justify;
    }
    .title {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 2rem;

      b {
        font-weight: 600;
      }
    }
    .introduce {
      margin-bottom: 0.5rem;
      width: 100%;
    }
    h3 {
      font-weight: 600;
    }
    .block {
      display: flex;
      flex-direction: column;
      grid-template-columns: 100%;
      grid-template-rows: 4rem auto auto;

      .doubleBlock {
        display: flex;
        flex-direction: column;
        h3.first {
          order: 1;
        }
        p.first {
          order: 2;
        }
        h3.second {
          order: 3;
        }
        p.second {
          order: 4;
        }
        .subtitle {
          margin-bottom: 0 !important;
        }
      }

      .subtitle {
        margin-bottom: 2rem !important;
      }

      .subtitle {
        margin-top: 1rem;
        padding: 0;
        font-weight: 800;
        font-size: 1.125rem;

        &.grey {
          color: var(--grey-font);
          margin-top: 0.5rem;
        }
      }
    }
  }
}
