
/*
    Fonts
*/

$muller: "Muller";
$font-family-base: var(--muller), sans-serif;

$font-size-base: 16px;
$rem-baseline: 16px;
$rem-fallback: true;
