@import "../../assets/style/variables";
@import '../../assets/style/mixins';

$font-size: 1.5rem;

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0;
  -webkit-text-fill-color: var(--darkblue);
  transition: background-color 6000s;
  transform: none;
}

.field {
  margin-bottom: 0.75rem;
  width: 100%;
  max-width: 100%;
  font-size: $font-size;

  text-align: left;

  &.fullWidth {
    width: 100% !important;
  }

  .inputContainer {
    position: relative;
  }

  .input {
    margin: auto;
    padding: 2rem 2rem 2rem 0;
    width: 100%;
    height: 3.5rem;
    color: var(--darkblue);
    font-weight: 600;
    background: transparent;
    font-size: $font-size;

    outline: none;
    border: none;

    line-height: 2;
    @media (max-height: 500px) {
      height: 2rem;
    }

    &::placeholder {
      transition: 100ms;
      background-color: transparent;
    }

    &:focus,
    &:not([value=""]),
    &:-webkit-autofill {
      &::placeholder {
        transition: 500ms;
        color: var(--grey);
        background-color: transparent;
      }
    }

    &::placeholder {
      color: var(--grey);
      font-weight: 600;
      background-color: transparent;
    }
  }

  .error {
    position: absolute;
    width: 20rem;
    color: red;
    font-weight: 500;
    font-size: 0.75rem;
    transform: translateY(-1rem);
  }

  @include for-mobile {
    font-size: 14px;
    margin-bottom: 0;
  }
}

.labelOuter {
  position: absolute;
  display: block;
  transition: 300ms;
  margin-bottom: 0;
  color: var(--grey);
  transform: translate(0, 1.25rem);
  font-weight: 600;
  white-space: nowrap;
  font-size: 1.5rem;

  :focus {
    transform: translate(0, 0);
    font-size: 0.75rem;
  }

  &.big {
    transform: translate(0, 0);
    font-size: 0.75rem;
  }

  @include for-mobile {
    font-size: 14px;
  }
}
