@import "../../assets/style/variables";
@import '../../assets/style/mixins';

.field {
    padding: 1rem 2rem 1rem 0;
    margin-bottom: 0.75rem;
    width: 100%;
    max-width: 100%;
    font-size: 0.875rem;
    text-align: left;

    &.fullWidth {
        width: 100% !important;
    }

    .inputContainer {
        position: relative;
    }

    .input {
        position: relative;
        display: block;
        width: 100%;
        padding: 0 0;
        color: var(--darkblue);
        font-weight: 600;
        background: transparent;
        font-size: 1.5rem;
        outline: none;
        border: none;
        transition: 100ms;
        overflow: hidden;
        resize: none;

        &::placeholder {
            opacity: 0;
            transition: 100ms;
        }

        &:focus,
        &:not([value=""]),
        &:-webkit-autofill {
            &::placeholder {
                opacity: 0;
                transition: 500ms;
            }

            & ~ span {
                transform: translateY(-3.5rem);
                font-size: 1rem;
            }
        }

        &:disabled {
            opacity: 0.8;
        }

        &::placeholder {
            color: var(--grey);
            font-size: 1.5rem;
            font-weight: 600;
        }
    }

    .error {
        transition: 0.5s ease-in-out;
        position: absolute;
        padding-top: 0.25rem;
        color: red;
        font-weight: 500;
        font-size: 0.75rem;
        width: 20rem;
    }

    @include for-mobile{
        font-size: 14px;
        margin-bottom: 0;
    }
}

.labelOuter{
    position: absolute;
    display: block;
    transition: 300ms;
    margin-bottom: 0;
    color: var(--grey);
    transform: translate(0);
    font-weight: 600;
    white-space: nowrap;
    font-size: 1.5rem;
    @include for-mobile{
        font-size: 14px;
    }
    &.big{
        transform: translateY(-1rem);
        font-size: 0.75rem;
    }
}

