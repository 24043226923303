@font-face {
  font-family: "Muller";
  src: url("../fonts/Muller/Fontfabric-MullerBlack.otf") format("truetype");
  font-weight: 900;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Muller/Fontfabric-MullerExtraBold.otf") format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Muller/Fontfabric-MullerHeavy.otf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Muller";
  src: url("../fonts/Muller/Fontfabric-MullerBold.otf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Muller";
  src: url("../fonts/Muller/Fontfabric-MullerMedium.otf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Muller";
  src: url("../fonts/Muller/Fontfabric-MullerRegular.otf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Muller";
  src: url("../fonts/Muller/Fontfabric-MullerLight.otf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Muller";
  src: url("../fonts/Muller/Fontfabric-MullerUltraLight.otf") format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: "Muller";
  src: url("../fonts/Muller/Fontfabric-MullerThin.otf") format("truetype");
  font-weight: 100;
}
