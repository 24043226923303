@import "fonts.scss";
@import "mixins.scss";
@import "variables";

html {
  box-sizing: border-box;
}

html,
body,
.root {
  overflow: hidden;
  overflow-y: auto;
  @include yCustomScroll;
  &::-webkit-scrollbar {
    width: 0;
  }

  margin: 0;
  padding: 0;
  min-height: 100%;
  font-size: $font-size-base;
  @for $i from 0 through 15 {
    @media(min-width: 1500px + $i * 300px) {
      font-size: $font-size-base + $i*3px;
    }
  }

  font-family: $font-family-base;
}

:root {
  --muller: "Muller";
  --font-family-base: var(--muller), sans-serif;

  --font-size-base: 16px;
  --rem-baseline: var(--font-size-base);
  --rem-fallback: true;

  --white: #fff;
  --black: #000;

  --background-color-grey: #f7f7f7;
  --active-color: #5465E6;
  --darkblue: #1A1B35;
  --grey: #9F9FA9;
  --grey-font: #CBCBD0;
  --mobile-padding: 2rem;
}

body {
  width: 100%;
  max-width: 100%;
  position: relative;
}

iframe {
  max-height: 100vh;
  max-width: 100vw;
}


*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
}

* {
  font-family: $muller;
}

h1 {
  margin: 0;
  font-size: 4rem;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
}

h2 {
  font-size: 1.5rem;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

button {
  &::-moz-focus-inner {
    border: none;
  }
}
