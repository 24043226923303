@import "../../assets/style/variables";
@import '../../assets/style/mixins';

.container{
  will-change: opacity, visibility, filter;
  transition: filter 1s ease-in-out, visibility 1s ease-in-out, opacity 1s ease-in-out;
  position: fixed;
  z-index: 5;
  right: 5.5rem;
  @include onReducePadding {
    right: 4rem;
  }
  top: 0;
  height: 100vh;
  display: flex;
  @include for-mobile {
    display: none;
  }
  .serifContainer{
    margin:auto;
    @include onReducePadding {
      max-height: 30vh;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .clickArea{
      cursor: pointer;
      height: 1.5rem;
      width: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .serif{
        transition: 1s ease-in-out;
        width: 16px;
        background-color: var(--grey-font);
        height: 2px;
        &.light{
          background-color: var(--white);
          transform: scaleX(2.5) translateX(-.3rem);
        }
        &.dark{
          background-color: var(--darkblue);
          transform: scaleX(2.5) translateX(-.3rem);
        }
      }
    }
  }

  &.blur{
    filter: blur(0.5rem);
    -webkit-filter: blur(0.5rem);
  }

  &.invisible{
    visibility: hidden;
    opacity: 0;
  }
}

.mouse{
  transition: 1s ease-in-out;
  position: absolute;
  bottom: 5rem;
  right: 0;
  @media screen  and (max-height: 350px){
    display: none;
  }
}
.dark{
  transition: .5s ease-in-out;
  color: var(--darkblue);
  path{
    transition: .5s ease-in-out;
    stroke-opacity: 0.5;
    stroke: #CBCBD0;
  }
  rect{
    transition: .5s ease-in-out;
    fill: var(--darkblue);
  }
}

.light{
  transition: .5s ease-in-out;
  color: var(--white);
  path{
    transition: .5s ease-in-out;
    stroke-opacity: 1;
    color: white;
  }
  rect{
    transition: .5s ease-in-out;
    fill: var(--white);
  }
}
