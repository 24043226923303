@mixin for-mobile {
  @media (max-width: 812px) { @content; }
}

@mixin yCustomScroll {
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #e8e8e8;
  }
}

@mixin mobile-portrait {
  @include for-mobile {
    @media (orientation: portrait) {
      @content;
    }
  }
}

@mixin mobile-landscape {
  @include for-mobile {
    @media (orientation: landscape) {
      @content;
    }
  }
}

@mixin not-mobile {
  @media (min-width: 813px) { @content; }
}

@mixin onReducePadding {
  @media only screen and (max-height: 550px) and (min-width: 813px) {
    @content;
  }
}

@mixin scrollPageStyles {
  width: 100vw;
  height: 100vh;
  @include for-mobile {
    height: auto;
  }
  @media only screen {
    overflow: hidden;
  }
  padding: 10rem 12rem 5.5rem 5.5rem;
  @include onReducePadding{
    padding: 6rem 8rem 2.5rem 1.5rem;
  }
  text-align: left;
}

@mixin titleFontSize {
  h1, h2 {
    text-align: left;
    margin: 0;
    font-size: 60px;
    line-height: 72px;
    @media only screen and (max-height: 800px), only screen and (max-width: 1400px) {
      font-size: 35px;
      line-height: 45px;
    }
    @media only screen and (max-width: 1000px) and (min-width: 813px){
      font-size: 28px;
      line-height: 38px;
    }
    @media only screen and (max-height: 600px) and (min-width: 813px) {
      font-size: 4.5vh;
      line-height: 5.5vh;
    }
  }

  h1 {
    font-weight: 200;
  }

  h2 {
    font-weight: 900;
  }
}

@mixin plainText {
  font-size: 28px;
  line-height: 36px;
  @media (max-width: 1000px) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  @media only screen and (max-height: 600px), only screen and (max-width: 900px) {
    font-size: 0.8rem;
    line-height: 1rem;
  }
}
