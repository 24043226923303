@import '../../assets/style/variables';
@import '../../assets/style/mixins';

.container {
  @include scrollPageStyles;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 8rem;
  background-color: var(--background-color-grey);
  font-size: 16px;
  line-height: 28px;
  @media only screen and (max-height: 850px), only screen and (max-width: 1450px) {
    font-size: 14px;
    line-height: 20px;
  }
  @media only screen and (max-height: 700px), only screen and (max-width: 1200px) {
    font-size: 14px;
    line-height: 20px;
  }
  color: var(--darkblue);
  @include for-mobile {
    padding: 2rem var(--mobile-padding) 2rem var(--mobile-padding);
  }

  .title {
    grid-area: title;
    display: flex;
    font-size: 20px;
    min-height: 6vh;
    @include titleFontSize;

    h1 {
      @include for-mobile {
        font-size: 20px;
      }
      margin-right: 0.25rem;
    }

    @media(min-width: 812px) {
      font-size: 60px;
      :first-child {
        font-weight: 100;
        margin-right: 0.75rem;
      }
      :last-child {
        font-weight: 900;
      }
    }
  }

  .textContent {
    display: flex;
    overflow: auto;
  }
  .left {
    grid-area: left;
    @include for-mobile {
      display: none;
    }
  }

  .right {
    grid-area: right;
    margin-left: 2rem;
    @include for-mobile {
      display: none;
    }
  }
  .left, .right {
    @include yCustomScroll;
  }

  .mobileText {
    display: none;
    @include for-mobile {
      display: flex;
      flex-direction: column;
    }
    white-space: pre-wrap;
    width: 100%;

    .button {
      margin-top: 1rem;
      cursor: pointer;
      color: var(--grey-font);
    }
  }

  .logo1 {
    grid-area: logo1;
    background-color: white;
    background-size: 100%;
    margin: 1rem 0;
    @include for-mobile {
      padding: 1rem 0;
      margin: 0;
    }

    & > div {
      height: 4rem;
      width: 4rem;
      @include for-mobile {
        height: 2rem;
        width: 2rem;
      }
      @media only screen and (max-height: 850px), only screen and (max-width: 1450px) {
        height: 3rem;
      }
      @media only screen and (max-height: 600px), only screen and (min-width: 813px) {
        height: 6vh;
      }
    }

    @for $i from 1 through 6 {
      .logo_#{$i} {
        background-image: image-set(
                        url("../../assets/images/05x/logo/logo#{$i}.png") 0.5x,
                        url("../../assets/images/logo/logo#{$i}.png") 1x
        );
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }

    .logo_1, .logo_2, .logo_3 {
      width: 6rem;
      @include for-mobile {
        width: 3rem;
      }
    }
  }

  .logo1, .logo2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
    width: 100%;
    @include for-mobile {
      width: calc(90vw - 1.5rem);
    }
    @media only screen and (max-height: 600px), only screen and (min-width: 813px) {
      padding: 2vh;
    }
  }

  .logo2 {
    grid-area: logo2;
    margin-top: -1rem;
    flex-wrap: wrap;

    @include for-mobile {
      padding: 0;
      margin-top: 0;
    }

    & > div {
      height: 64px;
      width: 12.5%;
      @include for-mobile {
        height: 48px;
        width: 48px;
        margin: 0 0.75rem;
      }
      @media only screen and (max-height: 850px), only screen and (max-width: 1450px) {
        height: 40px;
      }
      @media only screen and (max-height: 600px), only screen and (min-width: 813px) {
        height: 5vh;
      }
    }

    @for $i from 7 through 14 {
      .logo_#{$i} {
        background-image: image-set(
                        url("../../assets/images/05x/logo/logo#{$i}.png") 0.5x,
                        url("../../assets/images/logo/logo#{$i}.png") 1x
        );
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }

  }
}

.grey {

  color: var(--grey-font);
}
