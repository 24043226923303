@import '../../assets/style/variables';
@import '../../assets/style/mixins';

.container{
    @include scrollPageStyles;
    z-index: 6;
    background-image: image-set(
                    url("../../assets/images/05x/back_scroll06.png") 0.5x,
                    url("../../assets/images/back_scroll06.png") 1x
    );
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    display: grid;
    grid-template:
            'text photo ' auto
            /1fr 1fr;
    .text{
        margin: auto;
        display: flex;
        flex-direction: column;
        text-align: left;
        color: var(--white);
        grid-area: text;
        @include titleFontSize;
        p{
            padding-top: 1vw;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            @media only screen and (max-height: 650px), only screen and (max-width: 1000px) {
                font-size: 14px;
                line-height: 22px;
            }
            max-width: 66%;
        }
        h2{
            max-width: 90%;
        }
    }
    .photo{
        width: 100%;
        grid-area: photo;
        display: flex;
        .collage{
            position: relative;
            margin:auto;
            width:100%;
            height: 100%;
            background-image: image-set(
                            url("../../assets/images/05x/back_scroll06_gadgets.png") 0.5x,
                            url("../../assets/images/back_scroll06_gadgets.png") 1x
            );
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
    }
    @include mobile-landscape {
        height: 100vh;
        padding: var(--mobile-padding);
    }
    @include mobile-portrait {
        height: auto;
        padding: 1rem var(--mobile-padding) 4rem var(--mobile-padding);
        grid-template: 'photo' auto 'text' auto;
        .photo{
            min-width: 0;
            max-width: 85vmin;
            .collage{
                min-width: 85vmin;
                min-height:85vmin;
            }
        }
        .text {
            margin: 0;
            width: calc(100vw - 4rem);
        }
    }
    @include for-mobile {
        .text{
            *{
                text-align: left;
            }
            h1,h2{
                font-size: 20px;
                line-height: 24px;
            }
            h1{
                font-weight: 600;
            }
            h2{
                font-weight: 600;
            }
            p{
                padding-top: 1rem;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                min-width: 1rem;
                max-width: 90vw;
            }
        }
    }

}
