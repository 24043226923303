.carouselContainer {
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  .activeCard {
    height: 100%;
    opacity: 1;
    animation-name: appearFromLeft;
    animation-duration: 0.6s;
    z-index: 1;
    &.reverse {
      animation-name: appearFromRight;
      animation-duration: 0.6s;
    }
  }
  .previousCard {
    height: 0;
    animation-name: disappearToRight;
    animation-duration: 0.6s;
    z-index: 2;
    &.reverse {
      animation-name: disappearToLeft;
      animation-duration: 0.6s;
    }
  }
  &>div {
    height: 0;
    overflow: hidden;
    width: 100%;
    position: absolute;
    z-index: -1;
    opacity: 0;
  transition: opacity .6s ease-in-out;
  }
}

@keyframes appearFromLeft {
  0%   { transform:  translateX(-50%) rotateY(90deg); display: block; }
  100% { transform:  translateX(0); display: block; }
}
@keyframes appearFromRight {
  0%   { transform:  translateX(50%) rotateY(90deg) }
  100% { transform:  translateX(0) }
}
@keyframes disappearToLeft {
  0%   { transform:  translateX(0); height: 100% }
  99% { transform:  translateX(-50%) rotateY(90deg); height: 100% }
  100% { height: 0; overflow: hidden; }
}
@keyframes disappearToRight {
  0%   { transform:  translateX(0); height: 100%}
  99% {transform:  translateX(50%) rotateY(-90deg); height: 100%}
  100% { height: 0; overflow: hidden; }
}
