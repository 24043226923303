@import "../../assets/style/variables";

.container {
  height: 100%;
  width: 100%;
  display: flex;
  float: left;
  flex-direction: row;
  align-items: center;

  .iconContainer {
    transition: .5s;
    height: 6.5rem;
    width: 6.5rem;
    display: flex;
    border: transparent 1px solid;
    border-radius: 4rem;

    &.active {
      border: var(--active-color) 1px solid;

      .icon {
        background-color: var(--active-color);
      }
    }

    .icon {
      transition: .3s ease-in-out;
      cursor: pointer;
      display: flex;
      margin: auto;
      border-radius: 3rem;
      border: 1px transparent solid;
      width: 4.5rem;
      height: 4.5rem;
      background-color: var(--white);

      svg {
        margin: auto;
        max-height: 90%;
      }
    }
  }

  .textContainer {
    margin: auto 1rem;

    p {
      color: var(--darkblue);
      text-align: left;
      font-size: 1.125rem;
      line-height: 1.5rem;
      @media only screen and (max-width: 1000px) and (min-width: 813px){
        font-size: 0.8rem;
        line-height: 1rem;
      }
      margin: 0;

      &.active {
        color: var(--active-color);
      }
    }
  }
}


@media only screen and (max-height: 800px), only screen and (max-width: 1400px) {
  .container {
    .iconContainer {
      height: 4.5rem;
      width: 4.5rem;

      .icon {
        width: 3.5rem;
        height: 3.5rem;
      }
    }
  }
}

@media only screen and (max-height: 650px), only screen and (max-width: 1000px) {
  .container {
    .iconContainer {
      height: 7vh;
      width: 7vh;
      .icon {
        width: 5vh;
        height: 5vh;
      }
    }
  }
}
