@import "../../assets/style/variables.scss";
@import '../../assets/style/mixins';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 400px;
  width: 100vw;
  max-width: 100vw;
  max-height: 100vh;
  background-size: 100% auto;
  @include for-mobile {
    min-height: 100vh;
  }

  .scrollScreen {
    opacity: 0;
    transition: opacity 1s linear, transform 5s ease-out;
    @for $i from 1 through 6 {
      .back_#{$i} {
        position: absolute;
        transition: opacity .5s linear, transform 10s linear;
        width: 100vw;
        height: 100vh;
        background-image: image-set(
                        url("../../assets/images/mainScreen/back_scroll01_0#{$i}_main_05x.jpg") 0.5x,
                        url("../../assets/images/mainScreen/back_scroll01_0#{$i}_main.png") 1x
        );

        &.webp {
          background-image: image-set(
                          url("../../assets/images/mainScreen/back_scroll01_0#{$i}_main_05x.webp") 0.5x,
                          url("../../assets/images/mainScreen/back_scroll01_0#{$i}_main.webp") 1x
          );
        }

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;

        &.visible {
          opacity: 1;
          transform: scale(1.12);
        }
      }
      .centralHand_#{$i} {
        position: absolute;
        width: 65vw;
        height: 100vh;
        transform: translateX(35%);
        background-image: image-set(
                        url("../../assets/images/mainScreen/back_scroll01_0#{$i}_extra_05x.png") 0.5x,
                        url("../../assets/images/mainScreen/back_scroll01_0#{$i}_extra.png") 1x
        );
        &.webp {
          background-image: image-set(
                          url("../../assets/images/mainScreen/back_scroll01_0#{$i}_extra_05x.png") 0.5x,
                          url("../../assets/images/mainScreen/back_scroll01_0#{$i}_extra.webp") 1x
          );
        }

        background-size: 60vmin auto;
        background-repeat: no-repeat;
        background-position: bottom;
        @include for-mobile {
          bottom: 0;
          left: calc(50% - 20vh);
          width: 60vh;
          background-size: 100% auto;
          transform: none;
        }
      }
      .centralTable_#{$i}{
        left: 25%;
        position: absolute;
        width: 50vw;
        height: 100vh;
        background-image: image-set(
                        url("../../assets/images/mainScreen/back_scroll01_0#{$i}_extra_05x.png") 0.5x,
                        url("../../assets/images/mainScreen/back_scroll01_0#{$i}_extra.png") 1x
        );
        &.webp {
          background-image: image-set(
                          url("../../assets/images/mainScreen/back_scroll01_0#{$i}_extra_05x.png") 0.5x,
                          url("../../assets/images/mainScreen/back_scroll01_0#{$i}_extra.webp") 1x
          );
        }

        background-size: 45vmin auto;
        background-repeat: no-repeat;
        background-position: bottom;
        @media(max-width: 1200px) {
          width: 100%;
          left: 0;
          background-size: auto 80%;
          transform: none;
        }
      }
    }

    .bottomContent {
      width: calc(100vw - 11rem);
      margin: 0 5rem 0 5.5rem;
      position: absolute;
      bottom: 5rem;
      display: grid;
      grid-template: 'text  buttons' 11.5rem /auto 15rem;
      @include for-mobile {
        grid-template: 'text' 50% 'mobileButtons' 50%;
        margin: 0 var(--mobile-padding);
        bottom: var(--mobile-padding);
        width: calc(100vw - 2 * var(--mobile-padding));
        display: flex;
        flex-direction: column;
      }

      .buttons {
        @include for-mobile {
          display: none;
        }

        grid-area: buttons;
        margin: auto 0 0 auto;
        width: 15rem;
        height: 100%;
        display: flex;

        .playButton, .mouseButton {
          cursor: pointer;
          background-color: rgba(#e5e5e5, 0.5);
          border-radius: 4rem;
          width: 6rem !important;
          height: 6rem !important;
          display: flex;

          * {
            margin: auto;
          }

          margin: auto auto 0 auto;
        }

        .verticalLine {
          background-color: var(--white);
          width: 0.125rem;
          height: 2.5rem;
          position: absolute;
          right: 3.7rem;
          top: 12.5rem
        }
      }

      .mobileButton {
        @media(min-width: 812px) {
          display: none;
        }
        grid-area: mobileButtons;
        width: 12rem;
        height: 40px;
        display: flex;
        flex-direction: row;

        .button {
          border-radius: 40px;
          width: 40px;
          height: 40px;
          display: flex;
          background-color: rgba(white, 0.5);

          svg {
            width: 10px;
            height: 10px;
            margin: auto;
          }
        }

        .playText {
          font-weight: 600;
          letter-spacing: 2px;
          text-transform: uppercase;
          color: var(--white);
          display: flex;

          p {
            margin: auto 1rem;
          }
        }
      }

      .text {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        color: white;
        font-family: $muller;
        @include titleFontSize;

        @include for-mobile {
          h1, h2 {
            font-size: 1.5rem;
            line-height: 2rem;
          }
        }
      }
    }
  }

  .visible {
    opacity: 1;
  }
}

.blur {
  filter: brightness(50%);
  -webkit-filter: brightness(50%);
}
