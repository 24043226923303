@import "../../assets/style/variables";
@import "../../assets/style/Spinner";
@import '../../assets/style/mixins';
.container{
    @include scrollPageStyles;
    background: var(--background-color-grey);
    display: grid;
    grid-template:
            "header form " auto
            ".      .    " 1rem
            /auto   55%;

    @include for-mobile {
        position: relative;
        padding: var(--mobile-padding);
        height: auto;
        display: grid;
        grid-template: "header" 20rem 'form' 20rem 'googleTerms' 7rem;
        @media (orientation: landscape) {
            grid-template: "header" 20rem 'form' 20rem 'googleTerms' 7rem;
        }
        grid-row-gap: 5px;
        .header{
            position: initial;
            .top {
                position: initial;
                .thin, .bold {
                    font-size: 20px;
                    line-height: 24px;
                    font-weight: 600;
                }
            }
            .bottom{
                margin-top: 1rem;
                position: relative;

                p{
                    line-height: 18px;
                    font-size: 14px;
                }
                .email{
                    display: grid;
                    grid-template-columns: 2rem auto;
                    color: var(--darkblue);
                    :first-child{
                        margin: auto 0;
                    }
                    :last-child{
                        margin: auto auto auto 0;
                    }
                }
                .address{
                    padding: 1rem 0;
                    color: var(--grey);
                }
                .close{
                    padding: 0.25rem 0;
                }
                :last-child{
                    padding-bottom: 0;
                }
            }
            p{
                margin: 0;
                text-align: left;
            }
        }
        .form{
            .marginContainer{
                margin:  0;
                width: 100%;
                input, textarea{
                    font-size: 14px;
                }
            }
        }
        .copyright{
            font-size: 14px;
            color: var(--darkblue);
            position: absolute;
            left: var(--mobile-padding);
            bottom: 1rem;
            cursor: default;
        }
        .googleTerms{
            display: flex;
            flex-direction: column;
            justify-content: center;
            transform: translateY(2rem);
            font-size: 11px;
            :nth-child(2){
                display: flex;
                flex-direction: row;
                justify-content: center;
            }
            p{
                margin:0;
                text-align: center;
            }
            a{
                margin:auto 0.25rem;
                height: 0.75rem;
                color: var(--darkblue)
            }
            .charCounter{
                display: block;
                transform: translate(-0.5rem, -2rem);
                right: 0;
                position: absolute;
                font-size: 0.75rem;
            }
        }
    }
}

.header{
    position: relative;
    grid-area: header;
    margin: 0;
    .top{
        position: absolute;
        top: 0;
        color: var(--darkblue);
        @include titleFontSize;
        .thin, .bold {
            white-space: nowrap;
        }
    }
    .bottom{
        position: absolute;
        bottom: 0;
        p, a{
            line-height: 24px;
            font-size: 18px;
            @media only screen and (max-height: 800px), only screen and (max-width: 1400px) {
                font-size: 14px;
                line-height: 20px;
            }
            @media only screen and (max-height: 650px), only screen and (max-width: 1000px) {
                font-size: 11px;
                line-height: 14px;
            }
            @media only screen and (max-height: 600px) and (min-width: 813px){
                font-size: 2vh;
                line-height: 2.1vh;
            }
        }
        .email{
            display: flex;
            color: var(--darkblue);
            a {
                color: var(--darkblue);
            }
            :first-child{
                margin: auto 0;
            }
            :last-child{
                margin: auto auto auto 0;
            }
            @media only screen and (max-height: 600px) and (min-width: 813px){
                svg {
                    height: 3vh;
                    width: 3vh;
                }
            }
        }
        .address{
            color: var(--grey);
            padding: 1.75rem 0;
            @media only screen and (max-height: 800px), only screen and (max-width: 1400px) {
                padding: 20px 0;
            }
            @media only screen and (max-height: 600px) and (min-width: 813px){
                padding: 2.5vh 0;
            }
        }
        .close{
            padding: 0.25rem 0;
        }
        .terms{
            transform: translateY(-0.5rem);
            margin-top:1rem;
            color: var(--darkblue);
            cursor: pointer;
        }
        .policy{
            color: var(--darkblue);
            cursor: pointer;

        }
        :last-child{
            padding-bottom: 0;
        }
    }
    p{
        margin: 0;
        text-align: left;
    }

}
.copyright{
    color: var(--darkblue);
    position: absolute;
    left: -4rem;
    bottom: -5rem;
    cursor: default;
}
.form{
    grid-area: form;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    .marginContainer{
        width: 100%;
        flex: 1;
        padding: 2rem 2rem 2rem 2rem;
        @media only screen and (max-height: 650px) and (min-width: 813px){
            padding: 1rem;
        }
    }
}
.googleTerms{
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateY(2rem);
    color: var(--grey);
    font-size: 0.75rem;
    *{
        margin:0;
    }
    a{
        margin:auto 0.25rem;
        height: 0.75rem;
        color: var(--darkblue)
    }
    :nth-child(2){
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .charCounter{
        display: block;
        transform: translateY(0.75rem);
        right: 0;
        position: absolute;
        font-size: 0.75rem;
    }
}

.button{
    transition: color 0.5s ease-in-out;
    margin: auto 4rem 1rem 4rem;
    @media only screen and (max-height: 650px) and (min-width: 813px){
        margin: auto 2rem 0 2rem;
    }
    padding: unset;
    min-height: 2rem;
    height: 2rem;
    width:auto;
    display: flex;
    justify-content: space-between;
    border: none;
    background-color: transparent;
    color: var(--active-color);
    font-size: 2rem;
    font-weight: 900;
    outline: none;
    cursor: pointer;
    .loader{
        margin-top: -0.2rem;
        margin-left: 1.25rem;
    }
    *{
        display: flex;
        flex-direction: row;
    }
    &:disabled{
        color: var(--grey);
        cursor: not-allowed;
    }
    @include for-mobile{
        margin: 0 0 0 2rem;
        padding: 0 ;
        font-size: 1.5rem;
    }
}

.captcha{
    opacity: 0!important;
    pointer-events: none !important;
}

.error{
    color: var(--darkblue);
    height: 0;
    font-weight: 400;
    font-size: 0.75rem;
    padding-left: 2.125rem;
    text-align: left;
}

.afterSendLabel{
    grid-area: form;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--darkblue);
}
