@import '../../assets/style/mixins';

.container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  padding: 10rem 0 5.5rem 0;
  z-index: 200;
  left: 150%;
  @include for-mobile{
    padding: 0 1.5rem 0 1.5rem;
    display: flex;
  }

  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  color: white;

  &.active {
    transform: translateX(-150vw);
    opacity: 1;
    filter: none !important;
  }
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  @include for-mobile{
    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr;
    height: auto;
    margin: auto;
    &>div {
      margin-bottom: 1.5rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @include mobile-landscape {
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
    .text {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}

.text {
  @include titleFontSize;

  @include for-mobile{
    h1, h2 {
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      text-align: start;
    }
  }
}

.qrBlock {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 34px;
  @include for-mobile {
    grid-column-gap: 2px;
    height: 12rem;
    &>div {
      height: calc(12rem - 68px);
    }
    .qrImage img{
      height: 100%;
      margin: auto;
    }
  }
  padding: 34px;
  box-sizing: border-box;
  border: 1px solid #FAFAFA;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.16);
  border-radius: 5px;

  background: #FFFFFF;
  color: #1A1B35;

  .grey {
    color: #C8C8CF;
  }

  a {
    color: #1A1B35;
  }
}

.qrBlockText {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: start;
  line-height: 32px;
  @include for-mobile{
    line-height: 1rem;
    font-size: 0.6rem;
    flex: 1;
  }

  .title {
    font-weight: 600;
    font-size: 32px;
    @include for-mobile{
      font-size: 0.7rem;
    }
  }
}

.instructions {
  text-align: start;
  font-size: 18px;
  line-height: 40px;
  @include for-mobile{
    font-size: 15px;
    svg {
      height: 20px;
      width: 20px;
    }
  }
  color: #bcbcbc;
  span {
    margin-left: 0.5rem;
  }
  div {
    display: flex;
    align-items: center;
  }
}

.closeButton {
  position: absolute;
  top: 64px;
  right: 96px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  @include for-mobile{
    top: 0.5rem;
    right: 1.5rem;
    height: 3rem;
    svg{
      margin: auto 0 auto auto;
      width: 16px;
      path {
        stroke-width: 4;
      }
    }
  }
}
