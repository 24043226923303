@import '../../../assets/style/variables';
@import '../../../assets/style/mixins';

.container {
  padding: 4rem 1.5rem;
  background-color: var(--background-color-grey);
  color: var(--darkblue);
  display: flex;
  flex-direction: column;
  @include for-mobile {
    overflow: hidden;
    padding: 4rem var(--mobile-padding);
  }

  @include mobile-landscape {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1rem 2rem 1rem;
    >:nth-child(2) {
      grid-row-start: 1;
      grid-row-end: 5;
      grid-column-start: 2;
      order: -1;
    }
  }


  .carouselContainer {
    height: 35rem;
    margin: 2rem 0;
    @include mobile-landscape {
      height: calc(50vw - 2rem);
      margin: 0;
    }
  }

  .textCarouselContainer {
    height: 3rem;
    text-align: start;
    margin-top: 2rem;
    font-size: 14px;
    line-height: 21px;
    @include mobile-landscape {
      margin-top: 0;
    }
  }

  .text {
    text-align: left;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
  }
}

.imageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;


  img {
    height: 80%;
    @include mobile-landscape {
      height: 100%;
    }
  }
}
