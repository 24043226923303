@import "../assets/style/variables";
@import '../assets/style/mixins';

.container {
  width: 100%;
  white-space: pre-wrap;
  padding: 10rem 5.5rem 5.5rem 5.5rem;
  background-color: var(--background-color-grey);
  text-align: left;
  color: var(--darkblue);
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0.05rem;

  .topLevelList {
    > li::marker {
      font-size: 1.2em;
    }
  }

  .first {
    &>li{
      counter-increment: item;
    }
  }
  .second {
    counter-reset: item;
    list-style: none;
    padding-inline-start: 20px;
    &>li:before {
      content: counters(item,".") " ";
      counter-increment: item;
    }
  }


  .counterFromAttribute{
    list-style: none;
    padding-inline-start: 20px;
    &>li:before {
      content: attr(data-counter);
      width: 46px;
      display: inline-block;
      flex-shrink: 0;
    }
  }

  a {
    color: var(--darkblue);
    &:visited, &:active {
      color: var(--darkblue);
    }

    text-decoration: underline;
  }

  .title {
    font-weight: 200;
    font-size: 3.75rem;
    margin-bottom: 2rem;
    b{
      font-weight: 800;
    }
  }
  h3{
    font-weight: 800;
  }

  .grey {
    color: var(--grey-font);
    margin-top: 0;
  }
  .definition {
    margin: 1rem 0;
    b {
      color: var(--grey-font);
    }
  }
  @include for-mobile{
    padding: 6rem 2rem 2rem 2rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }
  .title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 2rem;
    b{
      font-weight: 600;
    }
  }
  h3{
    font-weight: 600;
  }

}
