@import "../../assets/style/variables";
@import '../../assets/style/mixins';

@include for-mobile{
  .navigationBarContainer {
    display: none !important;
  }
  .mobileNavigationBarContainer {
    display: flex !important;
  }
}

.navigationBarContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .arrows {
    width: 20%;
    display: flex;
    justify-content: space-around;
    button {
      outline: none;
      border: none;
      background: none;
      cursor: pointer;
      svg {
        height: 16px;
        @media only screen and (max-height: 650px), only screen and (max-width: 1000px) {
          height: 12px;
        }
      }
      &:hover {
        path {
          fill: var(--darkblue);
        }
      }
    }
  }
  .stateBar {
    position: relative;
    height: 1px;
    background-color: #CBCBD0;
    width: 50%;
    span {
      position: absolute;
      height: 2px;
      bottom: 0;
      background: var(--darkblue);
      transition: all 0.6s;
    }
  }
}

.mobileNavigationBarContainer {
  display: none;
  .circle {
    width: 8px;
    height: 8px;
    margin-right: 12px;
    border-radius: 4px;
    border: 1px solid var(--darkblue);
    &.selected {
      background: var(--darkblue);
    }
  }
}
