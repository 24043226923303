@import '../../assets/style/mixins';

.container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 8;
  left: 0;
  top: 0;
  transform: translateX(100vw);
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  color: white;
  overflow: hidden;

  &.active {
    transform: translateX(0);
    opacity: 1;
  }

  .right {
    width: 100vw;
    height: 100vh;
    padding: 8.7rem 10.5rem 3.7rem 5.5rem;
    @include onReducePadding {
      padding: 4.7rem 6.5rem 0.7rem 2.5rem;
    }
    @include for-mobile {
      padding: 5rem 5rem 3rem 2rem;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include mobile-landscape {
      display: grid;
      grid-template-columns: 1.3fr 1fr;
      grid-column-gap: 0.5rem;
    }

    .linkBlock {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: wrap;
      max-height: calc(100% - 4rem);
      height: 20rem;
      margin-top: 2rem;
      @include mobile-landscape {
        height: auto;
        max-height: unset;
        margin-top: 0;
      }


      .link {
        position: relative;
        cursor: pointer;
        z-index: 1000;
        text-align: left;
        font-size: 2.5rem;
        font-weight: 800;
        @media screen and (max-height: 500px) {
          font-size: 1.5rem;
          font-weight: 800;
        }
        @include for-mobile {
          font-size: 1.5rem;
          font-weight: 800;
        }
        @media screen and (max-height: 300px) {
          font-size: 1rem;
          font-weight: 800;
        }

        a {
          color: white;
          display: flex;
        }

        &:hover {
          .line {
            opacity: 1;
          }
        }

        .line {
          position: absolute;
          transition: opacity 300ms ease-in-out;
          background-color: white;
          left: 0;
          top: 50%;
          transform: translate(-6rem, -50%);
          width: 4.75rem;
          height: 2px;
          opacity: 0;
          @include onReducePadding {
            transform: translate(-5.5rem, -50%);
          }
          @include for-mobile {
            transform: translate(-5.5rem, -50%);
          }
        }
      }
    }
  }

  .left {
    left: 0;
    background-color: transparent;
  }
}

.contacts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(white, 0.5);
  font-weight: 500;
  font-size: 16px;
  @include for-mobile {
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
    & > * {
      margin: 0.5rem 0;
    }
  }
  @include mobile-landscape {
    & > * {
      margin: 0;
    }
    font-weight: normal;
    font-size: 12px;
  }

  .email {
    display: flex;
    align-items: center;

    a {
      color: white;
      margin-left: 0.35rem;
    }

    @include for-mobile {
      flex-direction: column;
      align-items: flex-start;
      a {
        margin-left: 0;
      }
    }
    @include mobile-landscape {
      margin-bottom: 15vh;
    }
  }

  .terms {
    display: flex;
    justify-content: center;

    > * {
      margin-left: 2rem;
      cursor: pointer;
    }

    @include for-mobile {
      display: contents;
      > * {
        margin: 0.5rem 0;
      }
    }
    @include mobile-landscape {
      > * {
        margin: 0;
      }
    }
  }
}
