@import '../../../assets/style/variables';
@import '../../../assets/style/mixins';

.container{
    @include scrollPageStyles;
    background-color: var(--background-color-grey);
    color: var(--darkblue);
    display: grid;
    grid-template:
        'text  smartphone .' 40%
        'icons smartphone .' 60%
        /2fr 1fr;
    .smartphone {
        display: flex;
        flex-direction: column;
        grid-area: smartphone;

        .switcher{
            transition: 1s ease-in-out;
            display: flex;
            justify-content: center;
            text-align: center;
            color: var(--grey-font);
            .or{
                width: 2rem;
                text-transform: none;
            }
            div{
                cursor: pointer;
                font-size: 1rem;
                text-transform: capitalize;
            }
            .underline{
                margin: 0.25rem 0;
                width: 100%;
                height: 0.0625rem;
                background-color: var(--grey-font);
            }
            .selected{
                transition: .3s;
                color: var(--darkblue);
                .underline {
                    transition: .3s;
                    background-color: var(--darkblue);
                    height: 0.125rem;
                }
            }
        }
    }
    .text{
        grid-area: text;
        @include titleFontSize;
    }
    .icons{
        margin-left: -1rem;
        @include onReducePadding {
            margin-left: 0;
        }
        grid-area: icons;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1rem;
    }
}

@for $i from 0 through 5 {
    .phone_#{$i}, .tablet_#{$i}{
        min-height: 90%;
        background-size: cover;
    }
    .phone_#{$i} {
        background-image: image-set(
                        url("../../../assets/images/05x/scroll4_phone#{$i}.png") 0.5x,
                        url("../../../assets/images/scroll4_phone#{$i}.png") 1x
        );
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    .tablet_#{$i}{
        background-image: image-set(
                        url("../../../assets/images/05x/scroll4_tablet#{$i}.png") 0.5x,
                        url("../../../assets/images/scroll4_tablet#{$i}.png") 1x
        );
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
}
