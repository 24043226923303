@import '../../assets/style/mixins';
@import '../../assets/style/mixins';

.container {
  @include scrollPageStyles;
  @include for-mobile {
    overflow: hidden;
  }
  background-color: var(--background-color-grey);

  display: flex;
  justify-content: space-between;
  color: var(--darkblue);
  @include plainText;

  @include for-mobile {
    position: relative;
    padding: 4rem 0 0 0;
    display: flex;
    flex-direction: column-reverse;
    font-size: 14px;
    line-height: 21px;
  }
  @include mobile-landscape {
    flex-direction: row;
  }

  &>div {
    width: calc(50% - 40px);
    @include for-mobile{
      width: 100%;
    }
    @include mobile-landscape {
      width: 50vw;
    }
  }

  .navigationTitle {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
}

.textWithNavigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 32rem;
  @include for-mobile {
    flex-direction: column-reverse;
    height: 12rem;
    padding: 0 var(--mobile-padding);
    margin-top: var(--mobile-padding);
    @media (max-width: 370px) {
      height: 16rem;
    }
  }
  @include mobile-landscape {
    height: calc(50vw - 4rem);
  }

  .navigationContainer {
    height: 5rem;
    width: 80%;

    > :first-child {
      margin-bottom: 0.5rem;
    }

    @include for-mobile {
      flex: inherit;
      width: 100%;
    }
  }
}

.plainText {
  @include yCustomScroll;
  overflow-y: scroll;
  flex: 1;
  margin-top: 1.5rem;
  @include for-mobile {
    margin: initial;
  }
}

.text {
  height: 100%;
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    flex-wrap: wrap;
    white-space: pre-wrap;
    @include for-mobile {
      display: none;
    }
  }

  @include titleFontSize;
}

.images {
  @include for-mobile {
    height: 35rem;
  }
  @include mobile-landscape {
    height: 50vw;
  }
  .imageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 80%;
    }
  }
}

.jumpScreenContainer {
  position: relative;
  height: 100%;
  .jumpScreen {
    width: 100%;
    position: absolute;
  }
  .appear {
    opacity:  1;
    animation-name: appeary;
    animation-duration: 1s;
  }
}

@keyframes appeary {
  0%   { opacity: 0 }
  100% { opacity:  1; }
}
