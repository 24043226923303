@import '../assets/style/mixins';

.container{
  position: fixed;
  background-color: rgba(white,0);
  width: 100%;
  z-index: 100;
  display:grid;
  grid-template-areas: "logo . register menu";
  grid-template-columns: 15rem auto 10rem 6rem;
  grid-template-rows: 3rem;
  padding: 4rem 5.5rem 0 4.5rem;
  transition: padding 0.5s ease-in-out, background-color 0.5s ease-in-out, opacity 0.5s;
  @include onReducePadding{
    padding: 1rem 4rem 1.5rem 1.5rem;
  }
  @include for-mobile{
    grid-template-columns: 12rem auto 0 auto;
    grid-template-rows: 3rem;
    padding: 1rem 1.5rem;
  }
  &.reduceOnScroll {
    transition: background, padding ease-out .5s;
  }
  .logo{
    grid-area: logo;
    display:flex;
    justify-content: left;
    svg{
      margin: auto;
      cursor: pointer;
    }
  }
  .register{
    @include for-mobile{
      display: none;
    }
    justify-content: flex-end;
    display: flex;
    cursor: pointer;
    will-change: visibility, opacity,  color;
    transition:  opacity .5s ease-in-out, visibility .5s ease-in-out, color .5s ease-in-out;
    font-weight: 600;
    letter-spacing: 0.125rem;
    visibility: visible;
    opacity: 1;
    margin:auto;
    grid-area: register;
    &.hidden{
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      cursor: none !important;
    }
  }
  .menuButton{
    display: flex;
    margin: auto 0 auto auto;
    justify-content: flex-end;
    cursor: pointer;
    grid-area: menu;
    @include for-mobile{
      transform: none !important;
      svg{
        width: 16px;
        path {
          stroke-width: 4;
        }
      }
    }
  }
  .counter{
    display: flex;
    position: absolute;
    background-color: rgba(green,0.25);
    right: 5.5rem;
    width: 3rem;
    height: 100vh;
  }
}

.dark{
  transition: .5s;
  color: var(--darkblue);
  & *{
    transition: .5s ease-in-out;
    fill: var(--darkblue);
  }
  a{
    color: var(--darkblue);
    &:visited, &:active {
      color: var(--darkblue);
    }
  }
}

.light{
  color: var(--white);
  & *{
    transition: .5s ease-in-out;
    fill: var(--white);
  }
  a{
    color: var(--white);
    &:visited, &:active {
      color: var(--white);
    }
  }
}

.whiteBackground{
  padding: 0 1.5rem ;
  background-color: rgba(white, 1);
}
