@import "../assets/style/variables";
@import '../assets/style/mixins';

$bcg: --background-color-grey;

.mainContainer{
  will-change: background;
  max-width: 100vw;
  height: 100%;
  transition: background .5s linear;
  z-index:-10;
  &.blur > div{
    filter: blur(0.5rem) brightness(50%);
    -webkit-filter: blur(0.5rem) brightness(50%);
  }
  &.static_1{
    background: #444646;
    &.darken{
      background-color: darken(#444646, 50%);
    }
  }

  &.static_0,&.static_2, &.static_3, &.static_4, &.static_7, &.static_8{
    background-color: var(--background-color-grey);
    &.darken{
      background-color: darken(#f7f7f7, 50%);
    }
  }
  &.static_5, &.static_6{
    background-color: rgb(29, 27, 34);
  }

  .contentContainer{
    display: flex;
    flex-direction: column;
    transition: filter 1s ease-in-out;
    &.blur{
      filter: blur(0.5rem) brightness(50%);
      -webkit-filter: blur(0.5rem) brightness(50%);
    }
  }
  .scrollbar{
    overflow-x: hidden;
  }
}

.player{
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 1000;
  opacity:1;
  visibility: visible;
  will-change: opacity, visibility;
  transition: 0.5s ease-in-out visibility, 0.5s ease-in-out opacity;
  .close{
    position: absolute;
    top: -3rem;
    right: -3rem;
    @include for-mobile {
      right: 50%;
      transform: translateX(50%);
    }
    cursor: pointer;
  }
  &.hidden{
    opacity: 0;
    visibility: hidden;
  }
}

.player iframe {
  max-width: 50vw;
  max-height: 50vh;
}
